import styled from '@emotion/styled';
import { setIsGlobalLoading } from '@store/configs/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const Custom404 = (): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setIsGlobalLoading({
        isLoading: false,
      })
    );
  }, []);

  return (
    <Wrap>
      <TitleWrap>
        <Title>페이지가 없습니다.</Title>
      </TitleWrap>
      <Desc>
        URL을 한 번 더 확인해 주세요.
        <br />
        문제가 지속될 경우 아래 이메일로 연락 바랍니다.
      </Desc>
      <Contact>admin@flarelane.com</Contact>
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 100%;
  height: 100%;
`;

const TitleWrap = styled.div`
  width: 100%;
  height: 380px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  padding: 32px;
  ${({ theme: { typography } }) => typography.heading1}
  background-color: ${({ theme: { colors } }) => colors.gray5};
  color: ${({ theme: { colors } }) => colors.blue0};
  text-align: center;
  margin-bottom: 32px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.span`
  ${({ theme: { typography } }) => typography.heading2}
  margin-bottom: 16px;
`;

const Desc = styled.div`
  text-align: center;
  color: ${({ theme: { colors } }) => colors.gray2};
  ${({ theme: { typography } }) => typography.mobileParagraph1}
  line-height: 1.6;
  margin-bottom: 32px;
`;

const Contact = styled.div`
  text-align: center;
  ${({ theme: { typography } }) => typography.paragraph1}
  color: ${({ theme: { colors } }) => colors.gray2};
`;

export default Custom404;
